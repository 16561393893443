
//statuses: ['awaiting-driver', 'driver-assigned', 'accepted', 'picked-up', 'dispatched', 'completed', 'cancelled'],
const tripStatuses = {
    'awaitingDriver': 'awaiting_driver',
    'driverAssigned': 'driver_assigned',
    'dispatched': 'dispatched',
    'accepted': 'accepted',
    'pickedUp': 'picked_up',
    'completed': 'completed',
    'cancelled': 'cancelled',
    'adminCancelled': 'admin_cancelled',
    'driverCancelled': 'driver_cancelled',
    'customerCancelled': 'customer_cancelled',
    // 'scheduled': 'scheduled',
    'onHold': 'on_hold',
    'returned': 'returned',
    'returning': 'returning'
};

const statuses = [
    { name: 'Awaiting Pro', values: tripStatuses.awaitingDriver, color: 'warning', sortNum: 1, },
    { name: 'Pro Cancelled', values: tripStatuses.driverCancelled, color: 'warning', sortNum: 1, },
    { name: 'Pro Assigned', values: tripStatuses.driverAssigned, color: 'indigo', sortNum: 2, },
    // { name: 'Scheduled', values: tripStatuses.scheduled, color: 'dark', sortNum: 3, },
    { name: 'Dispatched', values: tripStatuses.dispatched, color: 'info', sortNum: 3, },
    { name: 'On Hold', values: tripStatuses.onHold, color: 'dark', sortNum: 4, },
    { name: 'Accepted', values: tripStatuses.accepted, color: 'secondary', sortNum: 5, },
    { name: 'Picked Up', values: tripStatuses.pickedUp, color: 'primary', sortNum: 6, },
    { name: 'Completed', values: tripStatuses.completed, color: 'success', sortNum: 7, },
    { name: 'Cancelled', values: [tripStatuses.cancelled, tripStatuses.adminCancelled, tripStatuses.customerCancelled], color: 'danger', sortNum: 8, },
    { name: 'Returned', values: tripStatuses.returned, color: 'success', sortNum: 9, },
    { name: 'Returning', values: tripStatuses.returning, color: 'info', sortNum: 10, },
];

const invoiceStatuses = [
    { name: 'Draft', values: 'draft', color: 'warning', sortNum: 1, },
    // { name: 'Paid', values: 'paid', color: 'dark', sortNum: 2, }, this filter will show all statuses that include the letters 'paid', wating for BE filters
    { name: 'Unpaid', values: 'unpaid', color: 'danger', sortNum: 3, },
    { name: 'Overpaid', values: 'over_paid', color: 'info', sortNum: 4, },
    { name: 'Partial Paid', values: 'partial_paid', color: 'success', sortNum: 5, }
];
const accountStatuses = [
    { name: 'Business', value: 'true', sortNum: 1, },
    { name: 'Individual', value: 'false', sortNum: 3, },
    { name: 'All', value: 'all', sortNum: 2 }
];
const vehiclesType = [
    { type: 'bike', icon: 'bike', name: 'Bike' },
    { type: 'car', icon: 'car', name: 'Car' },
    { type: 'van', icon: 'van-utility', name: 'Van' },
    { type: 'truck', icon: 'truck', name: 'Truck' },
    { type: 'minivan', icon: 'van-passenger minivan-icon', name: 'Minivan' },
    { type: 'cargo', icon: 'caravan', name: 'Cargo' },
    { type: 'sprinter', icon: 'bus-side', name: 'Sprinter' },
];
const openTripStatuses = [
    tripStatuses.dispatched,
    tripStatuses.accepted,
    tripStatuses.pickedUp,
];
const cancelledTripStatuses = [
    tripStatuses.cancelled,
    tripStatuses.adminCancelled,
    tripStatuses.customerCancelled,
];
const tripHistoryStatuses = [
    tripStatuses.completed,
    tripStatuses.returned,
    ...cancelledTripStatuses
];
const dispatchedStatuses = [
    tripStatuses.dispatched,
    tripStatuses.completed,
    tripStatuses.accepted,
    tripStatuses.pickedUp
];

// can cancel when in these statuses
const cancelableStatuses = [
    tripStatuses.awaitingDriver,
    tripStatuses.driverCancelled,
    tripStatuses.driverAssigned,
    tripStatuses.dispatched,
    tripStatuses.accepted,
];

//can update to these statuses
const updateableStatuses = [
    tripStatuses.awaitingDriver,
    tripStatuses.driverAssigned,
    tripStatuses.accepted,
    tripStatuses.pickedUp,
    tripStatuses.completed,
    tripStatuses.adminCancelled,
    tripStatuses.onHold,
    tripStatuses.returning,
    tripStatuses.returned,
];

const paymentStatuses = ['paid', 'unpaid'];
const billingSchedules = [{ value: 'individual', label: 'Individual' }, { value: 'daily', label: 'Daily' }, { value: 'weekly', label: 'Weekly' }, { value: 'monthly', label: 'Monthly' }, { value: 'manual', label: 'Manual' }];
const chargeTypes = [
    // { value: null, label: '--Select payment method--', selected: true },
    { value: 'cc', label: 'Credit Card', selected: true },
    { value: 'ach', label: 'ACH' },
    { value: 'check', label: 'Check' },
    { value: 'zelle', label: 'Zelle/Quickpay' },
    { value: 'wire', label: 'Wire transfer' },
    { value: 'other', label: 'Other' },
    // { value: 'ccOutside', label: 'CC outside of this system'}
];
// const packageTypes = ['Bag', 'Box', 'Envelope', 'Garment', 'Open Wrap', 'Delicate', 'Other'];
const packageTypes = [];
const tags = [];
const settings = [];
const vehicleTypes = [];
const languages = [];


const requirementAction = [
    { value: 'allow_without', label: 'Allow without Signature', name: 'Allow without Signature', selected: true },
    { value: 'return_to_sender', label: 'Return to Sender', name: 'Return to Sender', selected: true },
    { value: 'return_to_hub', label: 'Return to Errands Hub', name: 'Return to Errands Hub' },
    { value: 'destroy', label: 'Destroy', name: 'Destroy' }
];

const genderType = [
    { value: '', label: 'Select...', name: 'Select...', selected: true, disabled: "disabled" },
    { value: 'male', label: 'Male', name: 'Male' },
    { value: 'female', label: 'Female', name: 'Female' },
    { value: 'prefer_not_to_say', label: 'Prefer Not to Say', name: 'Prefer Not to Say' },
];

export {
    statuses,
    openTripStatuses,
    tripHistoryStatuses,
    cancelledTripStatuses,
    dispatchedStatuses,
    cancelableStatuses,
    updateableStatuses,
    tripStatuses,
    paymentStatuses,
    packageTypes,
    tags,
    settings,
    billingSchedules,
    chargeTypes,
    vehicleTypes,
    languages,
    invoiceStatuses,
    requirementAction,
    accountStatuses,
    vehiclesType,
    genderType
};
