// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';

const Feedback = ({ errors, name, feedback }) => {
	return (errors && errors[name]) ? (
		<Form.Control.Feedback type="invalid">
			{errors[name]['message']}
		</Form.Control.Feedback>
	) : (feedback && feedback.type) ? (
		<Form.Control.Feedback type={feedback.type}>
			{feedback.message}
		</Form.Control.Feedback>
	) : null;
};

/* Password Input */
const PasswordInput = ({
	name,
	placeholder,
	refCallback,
	errors,
	feedback,
	register,
	className,
	floatingLabel,
	hidePasswordEye,
	type, // this is required for the password eye to work; if not specified, it will be in otherProps and override the type toggle
	...otherProps
}) => {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<InputGroup className="mb-0">
			<Form.Control
				type={showPassword ? 'text' : 'password'}
				placeholder={placeholder}
				name={name}
				id={name}
				as="input"
				ref={(r) => {
					if (refCallback) refCallback(r);
				}}
				className={classNames(className, (errors && errors[name]) ? 'is-invalid' : (feedback && feedback.type) ? `is-${feedback.type}` : '')}
				// isInvalid={errors && errors[name] ? true : false}
				{...(register ? register(name) : {})}
				autoComplete={name}
				{...otherProps}
			/>

			{hidePasswordEye ?
				null : < div
					className={classNames('input-group-text', 'input-group-password', {
						'show-password': showPassword,
					})}
					data-password={showPassword ? 'true' : 'false'}>
					<span
						className="password-eye"
						onClick={() => {
							setShowPassword(!showPassword);
						}}></span>
				</div>
			}
		</InputGroup >
	);
};

const MaskedFormInput = ({
	name,
	mask,
	placeholder,
	refCallback,
	errors,
	feedback,
	register,
	className,
	floatingLabel,
	...otherProps
}) => {
	return (
		<MaskedInput
			type="text"
			name={name}
			id={name}
			ref={(r) => {
				if (refCallback) refCallback(r);
			}}
			mask={mask}
			keepCharPositions={true}
			placeholder={placeholder}
			// isInvalid={errors && errors[name] ? true : false}
			{...(register ? register(name) : {})}
			className={classNames(className, (errors && errors[name]) ? 'is-invalid' : (feedback && feedback.type) ? `is-${feedback.type}` : '')}
			autoComplete={name}
			{...otherProps}
		/>
	);
};

/**
 * @typedef {{
 *  label?: string,
 *  floatingLabel?: string,
 *  type?: string,
 *  name?: string,
 *  placeholder?: string,
 *  register?: any,
 *  errors?: any,
 * feedback?: any,
 *  className?: string,
 *  labelClassName?: string,
 *  containerClass?: string,
 *  containerStyle?: string,
 *  refCallback?: any,
 *  children?: any,
 *  mask?: Array
 * }} FormInputProps
 */

const InputByType = (props) => {
	const {
		label,
		floatingLabel,
		type,
		name,
		placeholder,
		register,
		errors,
		feedback,
		className,
		labelClassName,
		containerClass,
		containerStyle,
		refCallback,
		children,
		mask,
		hidePasswordEye,
		...otherProps
	} = props;
	// handle input type
	const comp = type === 'textarea' ? 'textarea' : (type === 'select' || type === 'dropdown') ? 'select' : 'input';
	return (
		<>
			{type === 'hidden' ? (
				<input type={type} name={name} {...(register ? register(name) : {})} {...otherProps} />
			) : type === 'checkbox' || type === 'radio' ? (
				<>
					<Form.Check
						type={type}
						label={label}
						name={name}
						id={name}
						ref={(r) => {
							if (refCallback) refCallback(r);
						}}
						className={classNames(className, (errors && errors[name]) ? 'is-invalid' : (feedback && feedback.type) ? `is-${feedback.type}` : '')}
						// isInvalid={errors && errors[name] ? true : false}
						{...(register ? register(name) : {})}
						{...otherProps}
					/>

					<Feedback {...{ errors, name, feedback }} />
				</>
			) : (
				<>
					{label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}
					{type === 'password' ? (
						<PasswordInput
							{...props}
							{...(otherProps ? otherProps : {})}
						/>
					) : type === 'masked' ? (
						<MaskedFormInput
							{...props}
							{...(otherProps ? otherProps : {})}
						/>
					) : (
						<Form.Control
							type={type}
							placeholder={placeholder}
							name={name}
							id={name}
							as={comp}
							ref={(r) => {
								if (refCallback) refCallback(r);
							}}
							// className={className}
							className={classNames(className, (errors && errors[name]) ? 'is-invalid' : (feedback && feedback.type) ? `is-${feedback.type}` : '')}
							// isInvalid={errors && errors[name] ? true : false}
							{...(register ? register(name) : {})}
							{...otherProps}
							autoComplete={name}>
							{children ? children : null}
						</Form.Control>
					)}

					<Feedback {...{ errors, name, feedback }} />

				</>
			)
			}
		</>);
};

const FormInput = (props) => {

	const { floatingLabel, name, containerClass, containerStyle } = props;

	return (
		<Form.Group className={containerClass} style={{ ...containerStyle }}>
			{floatingLabel ?
				<Form.Floating>
					<InputByType {...props} />
					<label htmlFor={name} className="floatingLabel">{floatingLabel}</label>
				</Form.Floating>
				:
				<InputByType {...props} />
			}
		</Form.Group>
	);
};

export default FormInput;
