import React, { useEffect, useRef } from 'react';
import FormInput from './FormInput';
import FormInputNew from './FormInputNew';
import { getAddressObj } from '../helpers/addressHelper';
import swal from 'sweetalert';

export default function AddressInput({
	onChange,
	types,
	readOnly,
	type,
	feedback,
	...rest
}) {
	const input = useRef();

	useEffect(() => {
		const options = {};
		if (types) {
			options.types = types;
		}

		if (!readOnly) {
			const autocomplete = new window.google.maps.places.Autocomplete(
				input.current,
				options
			);
			autocomplete.setFields(["address_component", "formatted_address", "name", "geometry"]);

			const listener = () => {
				console.log('place changed');
				const place = autocomplete.getPlace();
				if (!place.geometry && place.name !== '') {
					// User entered the name of a Place that was not suggested and
					// pressed the Enter key, or the Place Details request failed.
					window.alert("No details available for input: '" + place.name + "'");
					return;
				}

				const addressObj = getAddressObj(place);

				input.current.value = addressObj.formattedAddress;
				onChange(addressObj, type);
			};
			autocomplete.addListener('place_changed', listener);
			return () => {
				window.google.maps.event.clearInstanceListeners(autocomplete);
			};
		}
	}, []);

	return (
		<>
			{feedback ?
				<FormInputNew
					type="text"
					name={(type ?? '') + "Address"}
					refCallback={(r) => {
						input.current = r;
					}}
					readOnly={readOnly}
					feedback={feedback}
					{...rest}
				/>
				:
				<FormInput
					type="text"
					name={(type ?? '') + "Address"}
					refCallback={(r) => {
						input.current = r;
					}}
					readOnly={readOnly}
					{...rest}
				/>}
		</>
	);
}
