import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { statuses, paymentStatuses, packageTypes, billingSchedules, chargeTypes, requirementAction, genderType } from './sources';
import { BooleanCell, AddressCell, LinkCell, PhoneCell, LanguagesCell, VehiclesType, PhoneType } from '../custom/Cells';

const DRIVER_INFO_FIELDS = [
    { fieldname: 'fullName', Header: 'Name', accessor: 'fullName', required: true },
    { fieldname: 'companyName', Header: 'Company Name', accessor: 'companyName', hidden: false },
    { fieldname: 'email', Header: 'Email', accessor: 'user.email', type: 'email' },
    {
        fieldname: 'smsNumber',
        Header: 'Phone Number',
        accessor: 'smsNumber',
        type: 'tel',
        required: true,
        Cell: PhoneCell,
    },
    { fieldname: 'socialSecurityNumber', Header: 'SSN/EIN', accessor: 'socialSecurityNumber', type: 'text' },
    { fieldname: 'gender', Header: 'Gender', accessor: 'user.gender', hidden: false, type: 'select', source: genderType },
];

const DRIVER_ADDRESS_FIELDS = [
    { fieldname: 'formattedAddress', Header: 'Home Address', accessor: 'address', type: 'address', display: true },
    { fieldname: 'streetLine2', Header: 'Address Line 2', accessor: 'address.streetLine2', display: true },


];

const DRIVERS_FIELDS = [
    // { fieldname: 'driverID', Header: 'Driver ID', accessor: 'id', readOnly: true, hidden: true },
    { fieldname: 'fullName', Header: 'Name', accessor: 'fullName', required: true },
    { fieldname: 'email', Header: 'Email', accessor: 'user.email', type: 'email' },
    { fieldname: 'smsNumber', Header: 'Phone Number', accessor: 'smsNumber', type: 'tel', required: true, Cell: PhoneCell, },
    { fieldname: 'companyName', Header: 'Company Name', accessor: 'companyName', hidden: false },
    { fieldname: 'gender', Header: 'Gender', accessor: 'user.gender', hidden: false, type: 'select', source: genderType },
    { fieldname: 'active', Header: 'Active', accessor: 'active', type: 'checkbox', new: false, Cell: BooleanCell },
    {
        fieldname: 'smsOptIn',
        Header: 'Text Notification',
        accessor: 'smsOptIn',
        type: 'checkbox',
        new: false,
        Cell: BooleanCell,
    },
    {
        fieldname: 'notificationOptIn',
        Header: 'App Notifications',
        accessor: 'notificationOptIn',
        type: 'checkbox',
        new: false,
        Cell: BooleanCell,
    },
    { fieldname: 'onDuty', Header: 'On Duty', accessor: 'onDuty', type: 'checkbox', new: false, Cell: BooleanCell },
];
const DRIVER_AVAILABILITY_FIELDS = [
    {
        fieldname: 'languages',
        Header: 'Languages',
        accessor: 'languages',
        type: 'languages',
        Cell: LanguagesCell,
    },
    {
        fieldname: 'vehicleTypes',
        Header: 'Vehicle Type',
        accessor: 'vehicleTypes',
        type: 'vehicleTypes',
        new: false,
        Cell: VehiclesType,
    },
    // {
    //     fieldname: 'phoneType',
    //     Header: 'Phone Type',
    //     accessor: 'phoneType',
    //     type: 'phoneType',
    //     new: false,
    //     Cell: PhoneType,
    // },
    {
        fieldname: 'sixthirteen',
        Header: '613',
        accessor: 'sixthirteen',
        type: 'checkbox',
        new: false,
        Cell: BooleanCell,
    },
    { fieldname: 'onDuty', Header: 'On Duty', accessor: 'onDuty', type: 'checkbox', new: false, Cell: BooleanCell },
    { fieldname: 'active', Header: 'Active', accessor: 'active', type: 'checkbox', new: false, Cell: BooleanCell },
    {
        fieldname: 'smsOptIn',
        Header: 'Text Notification',
        accessor: 'smsOptIn',
        type: 'checkbox',
        new: false,
        Cell: BooleanCell,
    },
    {
        fieldname: 'notificationOptIn',
        Header: 'App Notifications',
        accessor: 'notificationOptIn',
        type: 'checkbox',
        new: false,
        Cell: BooleanCell,
    },
];

const CUSTOMERS_FIELDS = [
    // { fieldname: 'customerID', Header: 'Customer ID', accessor: 'id', readOnly: true, hidden: true },
    { fieldname: 'fullName', Header: 'Name', accessor: 'fullName', required: true, hidden: false },
    { fieldname: 'companyName', Header: 'Company Name', accessor: 'companyName', hidden: false },
    { fieldname: 'phoneNumber', Header: 'Phone Number', accessor: 'phoneNumber', type: 'tel', required: true, Cell: PhoneCell },
    { fieldname: 'email', Header: 'Email', accessor: 'email', type: 'email' },
    { fieldname: 'emailForBilling', Header: 'Billing Email', accessor: 'emailForBilling', type: 'email' },
    { fieldname: 'orderform_websiteLink', Header: 'Website', accessor: 'orderform.websiteLink', hidden: false },
    { fieldname: 'orderform_showLogoOnWebsite', Header: 'Show logo on website', accessor: 'orderform.showLogoOnWebsite', type: 'checkbox', hidden: false },
    { fieldname: 'orderform_link', Header: 'Unique Link', accessor: 'orderform.link', type: 'linkField', link: process.env.REACT_APP_ORDER_URL, Cell: LinkCell, hidden: false },
    { fieldname: 'gender', Header: 'Gender', accessor: 'user.gender', hidden: false, type: 'select', source: genderType },
    // { fieldname: 'business', Header: 'Business', accessor: 'business', type: 'checkbox', Cell: BooleanCell, hidden: false },
    // { fieldname: 'chargeType', Header: 'Charge Type', accessor: 'chargeType', type: 'select', source: chargeTypes, hidden: true },
    // { fieldname: 'billingSchedule', Header: 'Billing Schedule', accessor: 'billingSchedule', type: 'select', source: billingSchedules },
];

const CUSTOMERS_SETTING_FIELDS = [
    { fieldname: 'requireSignature', Header: 'Require signature for all orders', accessor: 'requireSignature', type: 'checkbox' },
    { fieldname: 'requirementAction', Header: 'No-Signature Action', accessor: 'requirementAction', type: 'select', source: requirementAction },
    { fieldname: 'document', Header: 'Signature Document', accessor: 'document', type: 'textarea', }
];

const NEW_CUSTOMERS_FIELDS = [
    // { fieldname: 'customerID', Header: 'Customer ID', accessor: 'id', readOnly: true, hidden: true },
    { fieldname: 'fullName', Header: 'Name', accessor: 'fullName', required: true, hidden: false },
    { fieldname: 'companyName', Header: 'Company Name', accessor: 'companyName', hidden: false },
    {
        fieldname: 'phoneNumber',
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        type: 'tel',
        required: true,
        Cell: PhoneCell
    },
    { fieldname: 'email', Header: 'Email', accessor: 'email', type: 'email' },
    { fieldname: 'emailForBilling', Header: 'Billing Email', accessor: 'emailForBilling', type: 'email' },
    {
        fieldname: 'orderform_websiteLink', Header: 'Website', accessor: 'orderform.websiteLink', disabled: true
    },
    {
        fieldname: 'orderform_showLogoOnWebsite', Header: 'Show logo on website', accessor: 'orderform.showLogoOnWebsite', type: 'checkbox', hidden: false, disabled: true
    },
    {
        fieldname: 'orderform_link',
        Header: 'Unique Link',
        accessor: 'orderform.link',
        type: 'linkField',
        link: process.env.REACT_APP_ORDER_URL,
        Cell: LinkCell,
        disabled: true
    },
    // { fieldname: 'gender', Header: 'Gender', accessor: 'user.gender', hidden: false, type: 'select', source: genderType },

    // { fieldname: 'business', Header: 'Business', accessor: 'business', type: 'checkbox', Cell: BooleanCell, hidden: false },
    // { fieldname: 'chargeType', Header: 'Charge Type', accessor: 'chargeType', type: 'select', source: chargeTypes, hidden: true },
    // { fieldname: 'billingSchedule', Header: 'Billing Schedule', accessor: 'billingSchedule', type: 'select', source: billingSchedules },
];

const PAYMENT_FIELDS = [
    { fieldname: 'chargeType', Header: 'Charge Type', accessor: 'chargeType', type: 'select', source: chargeTypes },
    {
        fieldname: 'billingSchedule',
        Header: 'Billing Schedule',
        accessor: 'billingSchedule',
        type: 'select',
        source: billingSchedules,
    },
    {
        fieldname: 'chargeAutomatically',
        Header: 'Charge Automatically',
        accessor: 'chargeAutomatically',
        type: 'checkbox',
    },
    { fieldname: 'emailInvoice', Header: 'Email Invoice', accessor: 'emailInvoice', type: 'checkbox' },
    { fieldname: 'emailCSV', Header: 'Email CSV', accessor: 'emailCSV', type: 'checkbox' },
    {
        fieldname: 'terms_discussed', Header: 'Price Discussed', accessor: 'terms_discussed', type: 'checkbox',
    },
];

const ORDER_FIELDS = [
    {
        fieldname: 'orderform_address',
        Header: 'Address',
        accessor: 'orderform.address',
        type: 'address',
        Cell: AddressCell,
    },
    {
        fieldname: 'orderform_addressDisplay',
        Header: 'Address',
        accessor: 'orderform.address',
        hidden: true,
    },
    { fieldname: 'orderform_addressLine2', Header: 'Address Line 2', accessor: 'orderform.address.streetLine2' },
    { fieldname: 'orderform_pickupName', Header: 'Pickup Name', accessor: 'orderform.pickupName' },
    { fieldname: 'orderform_numberOfPackages', Header: 'Quantity', accessor: 'orderform.numberOfPackages', type: 'number' },
    {
        fieldname: 'orderform_packageType',
        Header: 'Package Type',
        accessor: 'orderform.packageType',
        type: 'select',
        source: packageTypes,
    },
    { fieldname: 'orderform_note', Header: 'Note', accessor: 'orderform.note' },
    // { fieldname: 'orderform_logoLink', Header: 'Logo Link', accessor: 'orderform.logoLink', },
];

const INVOICES_FIELDS = [
    { fieldname: 'invoiceNumber', Header: 'Invoice Number', accessor: 'invoiceNumber' },
    { fieldname: 'customer', Header: 'Customer', accessor: 'customer.fullName' },
    // { fieldname: 'trips' },
    { fieldname: 'invoiceDate', Header: 'Date', accessor: 'invoiceDate' },
    { fieldname: 'total', Header: 'Total Amount', accessor: 'totalPrice', type: 'currency' },
    { fieldname: 'status', Header: 'Status', accessor: 'status' },
];

const TRIPS_SCHEMA = yupResolver(
    yup.object().shape({
        pickupName: yup.string().optional(),
        pickupPhone: yup.string().optional(),
        pickupAddress: yup.string().optional(),
        dropoffName: yup.string().optional(),
        dropoffPhone: yup.string().optional(),
        dropoffAddress: yup.string().optional(),
        status: yup.string().optional(),
        paymentStatus: yup.string().optional(),
        price: yup.number().positive().optional().nullable(),
        tripScheduleTime: yup.date().optional(),
        packageType: yup.string().optional(),
        note: yup.string().optional(),
    })
);

const DRIVERS_SCHEMA = yupResolver(
    yup.object().shape({
        firstName: yup.string().optional(),
        lastName: yup.string().optional(),
        smsNumber: yup
            .number()
            .positive()
            .integer()
            .optional()
            .nullable(true)
            .transform((val, originalVal) => (isNaN(val) ? null : val))
            .test('len', 'Must be exactly 10 characters', (val) => val === null || val.toString().length === 10),
    })
);

const CUSTOMERS_SCHEMA = yupResolver(
    yup.object().shape({
        firstName: yup.string().optional(),
        lastName: yup.string().optional(),
        companyName: yup.string().optional(),
        phoneNumber: yup
            .number()
            .typeError('Must be a number')
            .positive()
            .integer()
            .nullable(true)
            .transform((val, originalVal) => (originalVal.toString().length === 0 ? null : val))
            .test('len', 'Must be exactly 10 characters', (val) => val === null || val.toString().length === 10),
        billingEmail: yup.string().email('Must be a valid email').optional(),
    })
);

const TABLE_FIELDS = {
    DRIVERS_FIELDS,
    DRIVER_INFO_FIELDS,
    DRIVER_ADDRESS_FIELDS,
    DRIVER_AVAILABILITY_FIELDS,
    CUSTOMERS_FIELDS,
    CUSTOMERS_SETTING_FIELDS,
    ORDER_FIELDS,
    INVOICES_FIELDS,
    PAYMENT_FIELDS,

    // temp
    NEW_CUSTOMERS_FIELDS
};
const DETAIL_SCHEMA = {
    DRIVERS_SCHEMA: DRIVERS_SCHEMA,
    CUSTOMERS_SCHEMA: CUSTOMERS_SCHEMA,
    TRIPS_SCHEMA: TRIPS_SCHEMA,
};

export { TABLE_FIELDS, DETAIL_SCHEMA };
