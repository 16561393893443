import React, { useState, useEffect, createContext, useContext } from 'react';
import { UserContext } from './UserContext';
import api from '../helpers/api';
import { tripStatuses } from '../constants/sources';
import api2 from '../helpers/api2';

export const CustomerTripContext = createContext();

export default function CustomerTripProvider(props) {
    const [trips, setTrips] = useState([]);
    const [error, setError] = useState(false);
    const [tripLoading, setTripLoading] = useState(false);
    const [skip, setSkip] = useState(0);

    const { socket } = useContext(UserContext);

    const fetchLimit = 500;

    // driverCancelled must be shown as awaitingDriver
    const openTripStatuses = [
        tripStatuses.awaitingDriver,
        tripStatuses.driverCancelled,
        tripStatuses.driverAssigned,
        tripStatuses.dispatched,
        tripStatuses.accepted,
        tripStatuses.pickedUp,
        tripStatuses.returning,
    ];

    const getData = () => {
        setTripLoading(true);
        const queryParams = {
            status: { in: openTripStatuses },
            sort: { orderNumber: 'desc' },
            limit: fetchLimit,
            skip: skip //trips.length,
        };
        api2.get('trips', { params: queryParams })
            .then((res) => {
                const resData = res.data.map(e => ({ ...e, status: e.status === tripStatuses.driverCancelled ? tripStatuses.awaitingDriver : e.status }));
                setTrips(trips => [...trips, ...resData]);
                if (res.data.length === fetchLimit)
                    setSkip(skip => skip + fetchLimit);
                else
                    setTripLoading(false);
            })
            .catch((err) => {
                //setError(err?.error?.message);
                console.log(err);
                setTripLoading(false);
            });
    };

    useEffect(() => {
        if (error)
            setTimeout(() => setError(false), 3000);
    }, [error]);

    useEffect(() => {
        getData();
    }, [skip]);

    useEffect(() => {
        function newTrip(trip) {
            trip.status = trip.status === tripStatuses.driverCancelled ? tripStatuses.awaitingDriver : trip.status;
            if (openTripStatuses.includes(trip.status))
                setTrips(t => [trip, ...t]);
        }
        // update trip not working
        function update(trip) {
            trip.status = trip.status === tripStatuses.driverCancelled ? tripStatuses.awaitingDriver : trip.status;
            setTrips(t => {
                const temp = [...t];
                const index = temp.findIndex((e) => e.id === trip.id);
                if (index !== -1)
                    temp[index] = trip;
                else if (openTripStatuses.includes(trip.status))
                    temp.unshift(trip);
                return temp;
            });
        }
        function deleted(trip) {
            setTrips(trips => trips.filter((e) => !trip.includes(e.id)));
        }

        socket.current.on('trip:new', newTrip);
        socket.current.on('trip:update', update);
        socket.current.on('trip:deleted', deleted);

        return () => {
            socket.current.off('trip:new', newTrip);
            socket.current.off('trip:update', update);
            socket.current.off('trip:deleted', deleted);
        };
    }, []);

    return (
        <CustomerTripContext.Provider
            value={{
                trips,
                setTrips,
                tripLoading
            }}>
            {props.children}
        </CustomerTripContext.Provider>
    );
}


