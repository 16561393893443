import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import { notify } from '../custom/Notification';
import { UserContext } from './UserContext';
import api from '../helpers/api';
import qs from 'qs';
import { tripStatuses } from '../constants/sources';

export const TripContext = createContext();

export default function TripProvider(props) {
  const fetchLimit = 500;
  const dispatch = 'dispatch'; // dispatch page
  const scheduled = 'scheduled'; // scheduled trips page
  const { soundOn, socket } = useContext(UserContext);
  /* Dispatch Page */
  const [trips, setTrips] = useState([]);
  const [skip, setSkip] = useState(null);
  const [tripLoading, setTripLoading] = useState(false);
  const [fetchedAll, setFetchedAll] = useState(false);

  /* Dispatch Page - Interval for later-scheduled trips */
  const [ltTime, setLtTime] = useState();
  const [gtTime, setGtTime] = useState();
  const [intervalRunning, setIntervalRunning] = useState(false);

  /* Scheduled Trips Page */
  const [scheduledTrips, setScheduledTrips] = useState([]);
  const [skipScheduled, setSkipScheduled] = useState(null);
  const [scheduledTripsLoading, setScheduledTripsLoading] = useState(false);
  const [fetchedAllScheduled, setFetchedAllScheduled] = useState(false);

  /* listener to not rerender table when user is clicking */
  const eventListenerRef = useRef(false);
  const changes = useRef({ new: [], update: [], deleted: [] });

  function applyUpdates() {
    if (!(changes.current.new.length || changes.current.update.length || changes.current.deleted.length)) return; // to avoid an infinite loop
    if (eventListenerRef.current) return;

    let temp = changes.current;
    changes.current = { new: [], update: [], deleted: [] };

    /* new */
    if (temp.new.length) {
      const newtime = new Date();
      const newScheduled = temp.new.filter(t => t.tripScheduleTime > newtime.toISOString());
      newtime.setHours(newtime.getHours() + 6);
      const newCurent = temp.new.filter(t => t.tripScheduleTime <= newtime.toISOString());

      setTrips((t) => addNewTrips(t, newCurent));
      setScheduledTrips((t) => addNewTrips(t, newScheduled));
      for (let TripObject of temp.new) {
        console.log('new trip in socket', TripObject.orderNumber);
        // if (TripObject.dispatcher?.fullName === 'errands.nyc website') {
        notify(TripObject.id, soundOn);
        // }
      }
    }

    /* update */
    if (temp.update.length) {
      setTrips(t => getUpdatedTrips(t, temp.update, dispatch));
      setScheduledTrips(t => getUpdatedTrips(t, temp.update, scheduled));
    }

    /* deleted */
    if (temp.deleted.length) {
      setTrips(t => t.filter((e) => !temp.deleted.includes(e.id)));
      setScheduledTrips(t => t.filter((e) => !temp.deleted.includes(e.id)));
    }
  }


  useEffect(() => {
    if (![`/dispatch`, '/scheduled'].includes(window.location.pathname)) return;
    let timeout;
    const handleEvent = (event) => {
      /* clear prev timeout */
      clearTimeout(timeout);
      eventListenerRef.current = true;
      /* set timeout */
      timeout = setTimeout(() => {
        eventListenerRef.current = false;
        applyUpdates();
      }, 5000);
    };

    document.addEventListener('click', handleEvent, true);
    document.addEventListener('keypress', handleEvent, true);

    return () => {
      document.removeEventListener('click', handleEvent, true);
      document.removeEventListener('keypress', handleEvent, true);
      clearTimeout(timeout);
    };
  }, [window.location.pathname]);


  const statusesToExclude = [
    tripStatuses.returned,
    tripStatuses.completed,
    tripStatuses.adminCancelled,
    tripStatuses.customerCancelled,
  ];

  function getTrips() {
    setTripLoading(true);

    const queryParamsObj = {
      status: { not: { in: statusesToExclude } },
      sort: { orderNumber: 'desc' },
      limit: fetchLimit,
      skip: skip //trips.length,
    };

    if (intervalRunning) {
      queryParamsObj.tripScheduleTime = {
        lte: ltTime,
        gt: gtTime,
      };
    } else {
      //initial render
      queryParamsObj.tripScheduleTime = {
        lte: ltTime, // now + 6h
      };
    }
    const queryParams = qs.stringify(queryParamsObj);


    api(`trips?${queryParams}`)
      .then(res => {

        if (intervalRunning) {
          setTrips(t => [...t, ...res].sort((a, b) => b.orderNumber - a.orderNumber));
        } else {
          setTrips(t => [...t, ...res]);
        }
        ;
        if (res.length === fetchLimit && trips.length < 10000) {
          setSkip(s => s + fetchLimit);
        }
        else {
          setFetchedAll(true);
          setSkip(null);

          setTripLoading(false);
        }
      })
      .catch(() => setTripLoading(false));
  }

  function getScheduledTrips() {
    setScheduledTripsLoading(true);

    const queryParamsObj = {
      status: { not: { in: statusesToExclude } },
      sort: { orderNumber: 'desc' },
      limit: fetchLimit,
      skip: skipScheduled, //trips.length,

      tripScheduleTime: { gt: (new Date()).toISOString() },
    };

    // console.log(queryParamsObj.tripScheduleTime);

    const queryParams = qs.stringify(queryParamsObj);

    api(`trips?${queryParams}`)
      .then(res => {
        // console.log(res);

        setScheduledTrips(t => [...t, ...res]);
        // console.log(scheduledTrips);

        if (res.length === fetchLimit && scheduledTrips.length < 10000) {
          setSkipScheduled(s => s + fetchLimit);
        }
        else {
          setFetchedAllScheduled(true);
          setSkipScheduled(null);

          setScheduledTripsLoading(false);
        }
      })
      .catch(() => setScheduledTripsLoading(false));

  }


  // load trips on initial render
  useEffect(() => {
    /* stats for dispatch page */
    setTrips([]);
    setFetchedAll(false);
    setSkip(0);
    const time = new Date();
    time.setHours(time.getHours() + 6);
    // time.setMinutes(time.getMinutes() + 5); //5min
    setLtTime(time.toISOString());

    /* states for scheduled-trip page */
    setScheduledTrips([]);
    setFetchedAllScheduled(false);
    setSkipScheduled(0);
  }, []);


  useEffect(() => {
    if (skip === null) return () => { };
    console.log('dispatch page', skip, trips.length);
    getTrips();
  }, [skip]);

  useEffect(() => {
    if (skipScheduled === null) return () => { };
    console.log('scheduled-trip page', skipScheduled, scheduledTrips.length);
    getScheduledTrips();
  }, [skipScheduled]);

  /* for dispatch page, after loading all trips, set an interval to get later-scheduled trips */
  useEffect(() => {
    if (fetchedAll) {
      setIntervalRunning(true);
      const interval = setInterval(() => {
        const newtime = new Date();
        newtime.setHours(newtime.getHours() + 6);
        // newtime.setMinutes(newtime.getMinutes() + 5); //5min
        setLtTime(prevLtTime => {
          setGtTime(prevLtTime);
          return newtime.toISOString(); // Update ltTime with the new value
        });

        setSkip(0);
      }, 900000); //2*60000 //2min

      return () => clearInterval(interval);
    }
  }, [fetchedAll]);

  /**
   * @param {*} t a list of trip objects from the current state
   * @param {*} tripObjects a list of trip objects from the socket
   * @returns a new array of trip objects with the updated trip objects
   */
  const getUpdatedTrips = (t, tripObjects, page) => {
    const temp = [...t];
    tripObjects.forEach((trip) => {
      const index = temp.findIndex((e) => e.id === trip.id);
      let toUpdate = temp[temp.findIndex((e) => e.id === trip.id)];

      let remove = false;

      // remove completed and canceled trips from dispatch page
      if (statusesToExclude.includes(trip.status)) { remove = true; }

      // check the trip schedule time, according to the page
      if (page === dispatch) {
        const newtime = new Date();
        newtime.setHours(newtime.getHours() + 6);
        if (trip.tripScheduleTime >= newtime.toISOString()) { remove = true; }
      } else if (page === scheduled) {
        if (trip.tripScheduleTime < (new Date()).toISOString()) { remove = true; }
      }

      if (remove) {
        // remove from temp - if it exists
        if (index !== -1)
          temp.splice(index, 1);
      } else if (index !== -1) {
        // update temp with the new trip
        temp[index] = trip;
      } else {
        // add to temp, in order of order number
        // find the index of first trip where orderNumber is greater than the current trip
        let i = temp.findIndex(e => e.orderNumber < trip.orderNumber);
        if (i === -1) i = temp.length;
        temp.splice(i, 0, trip);
      }
    });
    return temp;
  };

  /**
   * @param {*} t a list of trip objects
   * @param {*} newTrips a list of trip objects
   * @description add new trips to the list of trips, if the trip already exists, it is not added
   * @returns a new array of trip objects 
   */
  const addNewTrips = (t, newTrips) => {
    newTrips = newTrips.filter(e => !(t.map(ee => ee.id)).includes(e.id));
    return [...newTrips, ...t];
  };

  useEffect(() => {
    function update(tripObjects) {
      if (Array.isArray(tripObjects)) changes.current = { ...changes.current, update: [...changes.current.update, ...tripObjects] };
      else changes.current = { ...changes.current, update: [...changes.current.update, tripObjects] };
      return applyUpdates();
    }
    function newT(TripObject) {
      if (Array.isArray(TripObject)) {
        changes.current = { ...changes.current, new: [...changes.current.new, ...TripObject] };
      } else {
        changes.current = { ...changes.current, new: [...changes.current.new, TripObject] };
      }
      applyUpdates();

    }

    function deleted({ ids }) { changes.current = { ...changes.current, deleted: [...changes.current.deleted, ...ids] }; applyUpdates(); }

    socket.current.on('trip:new', newT);
    socket.current.on('trip:new[]', newT);
    socket.current.on('trip:update', update);
    socket.current.on('trip:update[]', update);
    socket.current.on('trip:deleted', deleted);

    return () => {
      socket.current.off('trip:new', newT);
      socket.current.off('trip:new[]', newT);
      socket.current.off('trip:update', update);
      socket.current.off('trip:update[]', update);
      socket.current.off('trip:deleted', deleted);
    };
  }, []);

  return (
    <TripContext.Provider
      value={{
        trips,
        scheduledTrips,
        setTrips,
        setScheduledTrips,
        tripLoading,
        scheduledTripsLoading,
        ltTime,
        gtTime,
        intervalRunning,
      }}>
      {props.children}
    </TripContext.Provider>
  );
}


