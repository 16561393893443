import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import FormInput from '../components/FormInput';
import MaskedInput from 'react-text-mask';
import { Badge, OverlayTrigger, Popover, Tooltip, Modal, InputGroup, Form, Dropdown as ProfileDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { statuses, tripHistoryStatuses, openTripStatuses, tripStatuses, vehiclesType } from '../constants/sources';
import { matchSorter } from 'match-sorter';
import { UserContext } from '../contexts/UserContext';
import DatePicker from 'react-datepicker';
import { formatPhoneNumber, backgroundGenerator, valueFormatter } from '../utils/functions';
import defaultProfile from '../assets/images/defaultProfile.png';
import defaultBusinessProfile from '../assets/images/defaultBusinessProfile.png';
import '../../src/assets/scss/custom/customModalDriverAvailability.css';
import classNames from 'classnames';
import { toast } from 'react-toastify';

export const EditableCell = ({ value: initialValue, row: { index, original }, column: { id }, updateData }) => {
    const [value, setValue] = useState(initialValue);

    const onChange = (e) => {
        setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        if (value !== initialValue) updateData(original, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        if (value !== initialValue) setValue(initialValue);
    }, [initialValue]);

    if (id.includes('Phone')) {
        return (
            <MaskedInput
                style={value && value.length !== 10 ? { borderColor: 'red', borderWidth: 2 } : null}
                value={value ?? undefined}
                onChange={(e) => {
                    setValue(e.target.value.replace(/\D/g, ''));
                }}
                onBlur={onBlur}
                mask={['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                placeholder="(___) ___-____"
                className="form-control table-cell"
            />
        );
    }

    return (
        <FormInput
            disabled={!original.customer && (id.includes('dropoffAddress') || id.includes('pickupAddress'))}
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
            className="table-cell"
        />
    );
};

export const NoteCell = ({ row: { index, original } }) => {
    let note =
        (original.note && original.note.trim() !== '' ? original.note + '\n' : '');
    note = note
        .replace(/(<([^>]+)>)/gi, '')
        .split('\n')
        .join('<br>');
    note = (
        <div
            style={{
                padding: '2px',
                fontSize: '12px',
                resize: 'vertical',
                maxWidth: '100%',
                maxHeight: '100%',
                whiteSpace: 'normal',
                overflowY: 'auto',
            }}
            dangerouslySetInnerHTML={{ __html: note }}
        />
    );

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {note}
        </Tooltip>
    );
    return (
        <OverlayTrigger overlay={renderTooltip} placement="right" delay={{ show: 250, hide: 400 }} rootClose>
            {note}
        </OverlayTrigger>
    );
};

export const ZoneCell = ({ cell: { value }, row: { original }, column: { prefix } }) => {
    const zoneStyles = (zone) => ({
        backgroundColor: zone?.color,
        color: "white",
        fontSize: "15px",
        marginRight: "4px"
    });

    const zoneTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props}
        </Tooltip >
    );

    return <>
        {original[prefix + '_zone'] ?
            <OverlayTrigger
                placement={prefix === 'pickup' ? 'left' : 'right'}
                delay={{ show: 250, hide: 400 }}
                overlay={zoneTooltip(original[prefix + '_zone']?.name)}
            >
                <Badge className={`${prefix === 'pickup' ? 'left' : 'right'}-zone-badge zone-badge w-100 mt-1 py-1 px-0`}
                    style={zoneStyles(original[prefix + '_zone'])}>
                    <span className=''>{original[prefix + '_zone']?.abbreviation?.toUpperCase() ?? original[prefix + '_zone']?.name?.substring(0, 2)?.toUpperCase()}</span>
                </Badge>
            </OverlayTrigger>
            : null}
    </>;
};

export const PriceCell = ({ value: initialValue, row: { index, original }, column: { id, editable, setFocus }, updateData }) => {
    let note = original.dropoffNote?.replace(/(<([^>]+)>)/gi, "").split('\n').join('<br>');

    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <p dangerouslySetInnerHTML={{ __html: note }} />
                <button className="action-icon" onClick={(e) => updateData(original, 'priceFlag', false)}>
                    Remove Price Flag
                </button>
            </Popover.Body>
        </Popover>
    );
    const trigger = (
        <OverlayTrigger trigger="click" overlay={popover} rootClose>
            <button className="action-icon">
                <i className="mdi mdi-alert-circle" style={{ color: 'red' }}></i>
            </button>
        </OverlayTrigger>
    );
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <NumberCell
                {...{ value: initialValue, row: { index, original }, column: { id, editable, setFocus }, updateData }}
                label={original.priceFlag ? trigger : null}
            />
        </div>
    );
};

export const PaymentCell = ({ row: { index, original } }) => {
    let value = original.paymentStatus;
    return (
        <span className={classNames('mt-1 fs-6 badge', {
            'badge-secondary-lighten ': value === 'invoiced',
            'badge-dark-lighten': (value === 'paid' || value === 'closed'),
            'badge-warning-lighten': (value === 'unpaid' || value === 'not_invoiced'),
            'badge-danger-secondary': value === 'stashed',
        })}>{valueFormatter(value)}</span>
    );
};

export const NumberCell = ({ value: initialValue, row: { index, original }, column: { id, editable, setFocus }, updateData, label = null }) => {
    const [value, setValue] = useState(initialValue);

    // useEffect(() => {
    //     if (value !== initialValue) setValue(initialValue);
    // }, [initialValue]);

    const onChange = (e) => {
        const regex = new RegExp(/[\0\d]+/);
        const value = e.target.value;
        if (value === '' || regex.test(value)) {
            setValue(value);
        }
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        if (value !== initialValue) updateData(original, id, value);
    };

    return !editable ? (
        <div style={{ padding: '7.2px', textAlign: 'right' }}>{initialValue}</div>
    ) : (
        <FormInput
            disabled={
                (!original.customer && (id.includes('dropoffAddress') || id.includes('pickupAddress'))) ||
                id.includes('orderNumber')
            }
            value={value ?? undefined}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={setFocus}
            className={label ? 'table-cell labeled-form-control price-cell' : 'table-cell price-cell'}
            style={{ textAlign: 'right' }}
            label={label}
            type='number'
            step='0.01'
            min='0.01'
        />
    );
};

export const TextareaCell = ({
    value: initialValue,
    row: { index, original },
    column: { id, setFocus },
    updateData,
}) => {
    const [value, setValue] = useState(initialValue?.trim());

    const onChange = (e) => {
        setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        if (value !== initialValue) updateData(original, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        if (initialValue !== value) setValue(initialValue);
    }, [initialValue, index]);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {value ?? 'No note'}
        </Tooltip>
    );
    return (
        <>
            {value ? (
                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                    <textarea
                        style={{ fontSize: 12, border: 'none', height: '100%', width: '100%' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={setFocus}
                        value={value ?? undefined}></textarea>
                </OverlayTrigger>
            ) : (
                <textarea
                    style={{ fontSize: 12, border: 'none', height: '100%', width: '100%' }}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={setFocus}
                    value={value ?? undefined}></textarea>
            )}
        </>
    );
};

export const LinkCell = ({ value, onChange, required, name, link, disabled }) => {
    const { user } = useContext(UserContext);
    const [tooltipV, setTooltipV] = useState('Copy link');

    const copyLink = (e) => {
        setTooltipV('Link copied');
        navigator.clipboard.writeText(e);
    };
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {tooltipV}
        </Tooltip>
    );
    const renderLinkTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Go to Orderform
        </Tooltip>
    );

    return (
        <>
            <InputGroup className="mb-3">
                <InputGroup.Text className='fs-6 ms-0 ps-1 pe-0' id="basic-addon3">{link}</InputGroup.Text>
                <Form.Control
                    className="d-flex justify-content-end justify-content-sm-start ps-0 rounded-end fs-6 fw-bold"
                    type={'linkField'}
                    name={name}
                    required={required}
                    onChange={onChange}
                    value={value ?? undefined}
                    disabled={user.role === 'Customer' || disabled}
                >
                </Form.Control>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 130 }}
                    overlay={renderTooltip}
                    onExited={() => setTooltipV('Copy link')}>
                    <span
                        variant="outline light"
                        onClick={(e) => copyLink(link + (value !== null ? value : ''))}
                        style={{ fontSize: '24px', cursor: 'pointer' }}
                        className="mdi mdi-content-copy ms-1">
                    </span>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 130 }}
                    overlay={renderLinkTooltip}>
                    <a target='_blank'
                        className='text-decoration-none text-secondary'
                        rel='noopener noreferrer' href={link + (value !== null ? value : '')}>
                        <span
                            variant="outline light"
                            style={{ fontSize: '24px', cursor: 'pointer' }}
                            className="mdi mdi-open-in-new ms-1">
                        </span>
                    </a>
                </OverlayTrigger>
            </InputGroup >
        </>
    );
};

export const StatusCell = ({ cell: { value, column }, row: { original }, view = 'deletedTrips' }) => {
    const status = statuses.find((s) => s.values.includes(value));
    const color = original.deleted === true ? `bg-secondary` : (original.status === 'driver_cancelled' ? `bg-${status?.color} border border-2 border-danger` : `bg-${status?.color} `);

    const statusTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Order was already assigned to a pro
        </Tooltip>
    );

    const statusBadge = () => {
        return (
            <div style={{ display: 'block', minWidth: 90, margin: '0 4px', width: '100%', height: '100%' }}>
                <Badge
                    className={color}
                    style={{
                        fontSize: '.8em',
                        paddingTop: '10px',
                        width: '100%',
                        height: '87%',
                        marginTop: '3px',
                        justifyContent: 'center',
                    }}>
                    {status?.name}
                </Badge>
            </div>
        );
    };

    return (
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
            {original.status === 'driver_cancelled' ?
                <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 130 }}
                    overlay={statusTooltip}>
                    {statusBadge()}
                </OverlayTrigger>
                :
                statusBadge()
            }
        </div>
    );
};

export const PoNumberCell = ({ cell: { value, column }, row: { original }, view = 'deletedTrips' }) => {
    let poNumber =
        (original.poNumber && original.poNumber.trim() !== '' ? original.poNumber + '\n' : '');
    poNumber = poNumber
        .replace(/(<([^>]+)>)/gi, '')
        .split('\n')
        .join('<br>');
    poNumber = (
        <div
            style={{
                padding: '2px',
                fontSize: '12px',
                resize: 'vertical',
                maxWidth: '100%',
                maxHeight: '100%',
                whiteSpace: 'normal',
                overflowY: 'auto',
            }}
            dangerouslySetInnerHTML={{ __html: poNumber }}
        />
    );

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {poNumber}
        </Tooltip>
    );
    return (
        <OverlayTrigger overlay={renderTooltip} placement="right" delay={{ show: 250, hide: 400 }} rootClose>
            {poNumber}
        </OverlayTrigger>
    );
};

export const AddressCell = ({ cell: { value }, row: { original }, column: { prefix } }) => {
    const renderAddressTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props.city} {props.state}, {props.zipCode}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 130 }}
            overlay={renderAddressTooltip(value)}>
            <div
                className='d-flex'
                style={{ marginTop: "5px", marginBottom: '0px', flexWrap: 'wrap', paddingLeft: '5px' }}
            >
                <span style={{ lineHeight: '1.3em', width: '100%', marginTop: '0px', fontSize: '13px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{value?.street}</span>
                <span style={{ fontSize: '11px', lineHeight: '1.4em', opacity: '0.7', textOverflow: 'ellipsis', overflow: 'hidden' }}>{value?.streetLine2}</span>
            </div>
        </OverlayTrigger>
    );
};

export const AddressCellCustomer = ({ cell: { value }, row: { original }, column: { prefix } }) => {
    const renderAddressTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {original.orderform?.address?.street},{original.orderform?.address?.streetLine2}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 130 }}
            overlay={renderAddressTooltip(value)}>
            <div
                className='d-flex'
                style={{ marginTop: "5px", marginBottom: '0px', flexWrap: 'wrap' }}
            >
                <span style={{ lineHeight: '1.3em', width: '100%', marginTop: '0px', fontSize: '13px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{original.orderform?.address?.street}</span>
                <span style={{ fontSize: '11px', lineHeight: '1.4em', opacity: '0.7', textOverflow: 'ellipsis', overflow: 'hidden' }}>{original.orderform?.address?.streetLine2}</span>
            </div>
        </OverlayTrigger>
    );
};


export function DropdownCell(options, updateData, fieldName, searchInKeys, onNew, getValue, ...rest) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.textInput = React.createRef();
            this.forbiddenStatuses = openTripStatuses.concat(tripHistoryStatuses);
        }

        componentDidMount() {
            autocomplete(
                this.textInput.current,
                options,
                (e, v) => {
                    updateData(this.props.row.original, fieldName, v);
                },
                searchInKeys,
                onNew
            );
        }

        render() {
            const value = getValue ? getValue(this.props) : '';
            // const selected = options.find(
            //     (e) => e.value === this.props.cell.value || (e.id && e.id === this.props.cell.value?.id)
            // );

            return tripHistoryStatuses.includes(this.props.row.original.status) ? (
                <div style={{ padding: '7.2px' }}>{getValue(this.props)}</div>
            ) : (
                <div className="autocomplete">
                    <input
                        defaultValue={value}
                        className="form-control table-cell"
                        ref={this.textInput}
                        type="text"
                        placeholder="Select..."
                        {...rest}
                    />
                </div>
            );
        }
    };
};

export const Dropdown = ({ options, updateData, searchInKeys, onNew, getValue, containerStyle, ...rest }) => {
    const textInput = useRef();
    const value = getValue ? getValue() : '';

    useEffect(() => {
        autocomplete(textInput.current, options, updateData, searchInKeys, onNew);
    }, [options, updateData, onNew]);

    return (
        <div className="autocomplete" style={containerStyle}>
            <FormInput
                type="text"
                placeholder="Select..."
                defaultValue={value}
                // ref={textInput}
                refCallback={(r) => {
                    textInput.current = r;
                }}
                {...rest}
            />
        </div>
    );
};

export function autocomplete(inp, arr, onChange, searchInKeys, onNew) {
    if (!inp) return;
    var currentFocus;
    inp.addEventListener('input', handler);
    inp.addEventListener('focus', handler);
    function handler(e) {
        var a,
            b,
            i,
            val = this.value.replace(/[^\w]/g, "");
        closeAllLists();
        if (!val) {
            return false;
        }
        currentFocus = -1;
        a = document.createElement('DIV');
        a.setAttribute('id', this.id + 'autocomplete-list');
        a.setAttribute('class', 'autocomplete-items');
        this.parentNode.appendChild(a);
        var localArr = matchSorter(arr, this.value.replace(/[^\w]/g, ""), {
            keys: searchInKeys,
        });
        localArr.filter((row) => !row.deleted);
        var equalArr = matchSorter(arr, this.value.replace(/[^\w]/g, ""), {
            keys: searchInKeys,
            threshold: matchSorter.rankings.CASE_SENSITIVE_EQUAL,
        });
        for (i = 0; i < Math.min(localArr.length, 8); i++) {
            b = document.createElement('DIV');
            const temp = localArr[i];
            b.innerText = temp.label;
            b.innerHTML += "<input type='hidden' value='" + temp + "'>";
            b.onclick = function () {
                inp.value = temp.label;
                onChange(e, temp);
                closeAllLists();
            };
            a.appendChild(b);
        }
        if (typeof onNew === 'function' && equalArr.length === 0) {
            b = document.createElement('DIV');
            b.innerText = `Add '${inp.value.trim()}'`;
            b.onclick = function () {
                onNew(inp.value.trim());
                closeAllLists();
            };
            a.appendChild(b);
        }
    }

    inp.addEventListener('keydown', function (e) {
        var x = document.getElementById(this.id + 'autocomplete-list');
        if (x) x = x.getElementsByTagName('div');
        if (e.key === 'ArrowDown') {
            currentFocus++;
            addActive(x);
        } else if (e.key === 'ArrowUp') {
            currentFocus--;
            addActive(x);
        } else if (e.key === 'Enter') {
            e.preventDefault();
            if (currentFocus > -1) {
                if (x) x[currentFocus].click();
            } else if (e.target.value === '') {
                onChange(null);
            }
        }
    });

    function addActive(x) {
        if (!x) return false;
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = x.length - 1;
        x[currentFocus].classList.add('autocomplete-active');
    }

    function removeActive(x) {
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove('autocomplete-active');
        }
    }

    function closeAllLists(elmnt) {
        var x = document.getElementsByClassName('autocomplete-items');
        for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }
};

export const DatePickerCell = ({ item }) => {
    const [openDatePicker, setOpenDatePicker] = useState(false);

    return (
        <>
            <button title='Download Orders Report' className="nav-link" onClick={() => setOpenDatePicker(!openDatePicker)}>
                {item.icon && <i className={item.icon}></i>}
                <span>{item.label}</span>
            </button>
            <DatePicker
                onChange={item.onClick}
                onClickOutside={() => setOpenDatePicker(false)}
                open={openDatePicker}
                maxDate={new Date()}
                customInput={<span></span>}
            />
        </>
    );
};

export const SoundCell = ({ item }) => {
    const { soundOn, setSoundOn } = useContext(UserContext);

    const toggleSound = () => {
        toast.info(`Sound Notification ${soundOn ? 'Off' : 'On'}`);
        setSoundOn(!soundOn);
    };

    return (
        <div style={{ cursor: 'pointer' }} className="nav-link" onClick={() => toggleSound()} title={soundOn ? ' Sound On' : 'Sound Off'}>
            <i className={`${soundOn ? 'mdi mdi-bell text-success' : 'mdi mdi-bell-cancel text-danger'} fs-`}></i>

            {/* <Badge pill className={`badge-${soundOn ? 'success' : 'danger'}-lighten fs-5 px-2`}>{item.label}{soundOn ? ' On' : ' Off'}</Badge> */}
        </div>
    );
};

export const BooleanCell = ({ cell }) => {
    return <div className={`${cell.value ? 'h5 mdi mdi-check text-primary' : 'h5 mdi mdi-close text-danger'}`}></div>;
};

export const PhoneCell = ({ cell }) => {
    return formatPhoneNumber(cell?.value) || '';
};

export const EmailCell = ({ cell }) => {
    return (
        <div title={cell.value} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{cell.value}</div>
    );
};

export const TagsCell = ({ cell: { value }, column: { role } }) => {
    return (
        <>
            <div className="d-flex" style={{ flexWrap: 'wrap', overflowY: 'auto', maxHeight: '100%' }}>
                {value?.map(
                    (t) =>
                        (!role || t[role + 'Enabled']) && (
                            <Badge
                                key={t.description}
                                style={{ wordWrap: 'normal', backgroundColor: t.color }}
                                className="ms-1 mb-1">
                                {t.description}
                            </Badge>
                        )
                )}
            </div>
        </>
    );
};

export const LanguagesCell = ({ value, onChange }) => {
    return (
        <div style={{ display: 'flex' }}>

            <p className='space-lang'>
                <input id="en" name="en" value="en" type="checkbox" className="form-check-input space-checkbox" onChange={onChange}
                    checked={value && value.includes('en') ? true : false}
                />
                English
            </p>
            <p className='space-lang'>
                <input id="es" name="es" value="es" type="checkbox" className="form-check-input space-checkbox" onChange={onChange}
                    checked={value && value.includes('es') ? true : false}
                />
                Spanish
            </p>
            <p className='space-lang'>
                <input id="he" name="he" value="he" type="checkbox" className="form-check-input space-checkbox" onChange={onChange}
                    checked={value && value.includes('he') ? true : false}
                />
                Hebrew
            </p>
            <p >
                <input id="yi" name="yi" value="yi" type="checkbox" className="form-check-input space-checkbox" onChange={onChange}
                    checked={value && value.includes('yi') ? true : false}
                />
                Yiddish
            </p>
        </div>
    );
};

export const VehiclesType = ({ value, onChange }) => {
    return (
        <div className='d-flex'>
            <div style={{ display: 'flex', flexDirection: "column", width: "93px" }}>
                {vehiclesType.slice(0, 4).map((vehicle) => (
                    <p className='space-vehicle' key={vehicle.id}>
                        <input
                            name={vehicle.type}
                            value={vehicle.type}
                            type="checkbox"
                            className="form-check-input space-checkbox"
                            onChange={onChange}
                            checked={value && value.includes(vehicle.type) ? true : false}
                        />
                        {vehicle.name}
                    </p>
                ))}
            </div>
            <div>
                {vehiclesType.slice(4).map((vehicle) => (
                    <p className='space-vehicle' key={vehicle.id}>
                        <input
                            name={vehicle.type}
                            value={vehicle.type}
                            type="checkbox"
                            className="form-check-input space-checkbox"
                            onChange={onChange}
                            checked={value && value.includes(vehicle.type) ? true : false}
                        />
                        {vehicle.name}
                    </p>
                ))}
            </div>
        </div>
    );
};

export const PhoneType = ({ cell }) => {
    return (
        <div style={{ display: 'flex' }}>
            <p className='space-phone'>
                <input type="checkbox" className="form-check-input space-lang" />
                Apple
            </p>
            <p>
                <input type="checkbox" className="form-check-input space-lang" />
                Android
            </p>
        </div>
    );
};


export const ProfileImgCell = ({ row: { original } }) => {
    const customerTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props ? 'Business customer' : 'Regular customer'}
        </Tooltip>
    );

    let business = original?.customer?.business || original?.business;
    let url = process.env.REACT_APP_IMAGE_URL;
    let logoLink = original?.customer?.orderform?.logoLink || original?.orderform?.logoLink;
    return (
        <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={customerTooltip(original?.customer?.business || original?.business)}
        >
            <div className="table-user" style={{ marginTop: '3px' }}>
                <img src={business ? (logoLink ? (url + logoLink + '/50') : defaultBusinessProfile) : defaultProfile} alt="" className="me-2 rounded-circle" />
            </div>
        </OverlayTrigger>
    );
};

export const ProfileUploader = () => {
    const { user, profileImage } = useContext(UserContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    let business = user?.customer?.business;
    let url = process.env.REACT_APP_IMAGE_URL;

    return (
        <ProfileDropdown show={dropdownOpen} onToggle={toggleDropdown}>
            <ProfileDropdown.Toggle
                className='nav-link shadow-none m-0 p-0 mt-1 bg-none'
                bsPrefix='m-0'>
                <div className='bg-light rounded-circle'
                    style={{ width: '35px', height: '35px' }}
                    onClick={toggleDropdown}>
                    <img
                        style={{ width: '35px', height: '35px' }}
                        src={business ? (profileImage ? (url + profileImage) : defaultBusinessProfile) : defaultProfile}
                        alt="user image"
                        className="me-2 rounded-circle border border-light"
                    />
                </div>
            </ProfileDropdown.Toggle>
            <ProfileDropdown.Menu className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown rounded">
                <div onClick={toggleDropdown}>
                    <Link to="/accountsettings" className="dropdown-item notify-item mb-1">
                        <i className={`mdi mdi-account-cog-outline me-1`}></i>
                        <span>Account</span>
                    </Link>
                    <Link className="dropdown-item notify-item mb-1" onClick={(e) => {
                        e.preventDefault();
                        fetch(process.env.REACT_APP_API_URL + '/auth/logout', {
                            method: 'POST',
                            credentials: 'include',
                        }).then((e) => (window.location.href = '/'));
                    }}   >
                        <i className={`mdi mdi-logout me-1`}></i>
                        <span>Logout</span>
                    </Link>
                </div>
            </ProfileDropdown.Menu>
        </ProfileDropdown>
    );
};

export const MondayBoardComponent = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const width = window.innerWidth;
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(width > 1024 && width < 1609);
            setIsMobile(width <= 1024);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const smallerScreen = width < 1609 && width > 1586;

    return (
        <a className='nav-link'/* className='nav-link custom-component-nav' */ href="https://errands-nyc.monday.com/" target="_blank" rel="noopener noreferrer">
            <div>
                <i className="mdi mdi-gg--monday" ></i>
                <span style={{ fontSize: 'smaller' }}> Monday</span>
            </div>
        </a>
    );
};
